.mainTitle {
  font-size: 40px;
  align-self: center;
}
.globalFont {
  font-family: "Source Sans Pro";
  height: 100%;
  overflow: hidden;
  min-height: 100vh;
  background-color: #000000;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80' width='80' height='80'%3E%3Cpath fill='%23d8ab4c' fill-opacity='0.4' d='M14 16H9v-2h5V9.87a4 4 0 1 1 2 0V14h5v2h-5v15.95A10 10 0 0 0 23.66 27l-3.46-2 8.2-2.2-2.9 5a12 12 0 0 1-21 0l-2.89-5 8.2 2.2-3.47 2A10 10 0 0 0 14 31.95V16zm40 40h-5v-2h5v-4.13a4 4 0 1 1 2 0V54h5v2h-5v15.95A10 10 0 0 0 63.66 67l-3.47-2 8.2-2.2-2.88 5a12 12 0 0 1-21.02 0l-2.88-5 8.2 2.2-3.47 2A10 10 0 0 0 54 71.95V56zm-39 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm40-40a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM15 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm40 40a2 2 0 1 0 0-4 2 2 0 0 0 0 4z'%3E%3C/path%3E%3C/svg%3E");
}
.colCenter {
  display: flex;
  justify-content: center;
}

.smallText {
  font-size: 12px;
}

.colCenter1 {
  display: flex;
  justify-content: center;
  font-size: 30px;
}

.colCenter2 {
  display: flex;
  justify-content: center;
  font-size: 20px;
  line-height: 110%;
  padding-top: 10px;
}

@media only screen and (min-device-width : 600px) {
  .colCenter2 > center > p {
    width: 80%;
  }
}
.tempLogo {
  width: 150px;
  height: 195px;
}

.mainContainer {
  background-color: white;
  position: relative;
  top: 5vh;
  width: 40vw;
  padding: 20px;
  border-radius: 10px;
}

@media only screen and (max-device-width : 600px) {
  .mainContainer {
    width: 95vw;
  }

  .mainContainerAnimate {
    width: 95vw;
  }
}

.register {
  top: 5vh;
  position: relative;
  animation-name: animate_register;
  animation-duration: 0.5s;
}

@keyframes animate_up {
  from {
    top: 5vh;
  }
  to {
    top: -100vh;
  }
}
@keyframes animate_register {
  from {
    top: 100vh;
  }
  to {
    top: 5vh;
  }
}
