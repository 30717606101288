html {
  box-sizing: border-box;
  font-size: 100%;
}

*,
:after,
:before {
  box-sizing: inherit;
}

.testBody {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/vandyfood.appspot.com/o/bkg-compressed.png?alt=media&token=68953db6-7d2c-46db-9bbe-c6ae8afe3dcf");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  max-width: 100%;
  min-height: 100vh;
  height: auto;
  letter-spacing: 0.1em;
}

.logo {
  width: 300px;
}

h2 {
	font-size: 42px;
	color: #D8AB4C;
}

.links__item {
  display: block;
  max-width: 550px;
  margin: 0 auto 15px;
  margin-bottom: 0px;
  padding: 15px 20px;
  font-size: 1.2rem;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  color: black;
  font-family: Source Sans Pro;
}

.links__item .links__item_alumni:hover {
  background-color: #fff;
}

.links__item .links__item_upcoming:hover {
  background-color: #fff;
}

.links__item .links__item_promo:hover {
  background-color: #fff;
}

.links__item .links__item_past_event:hover {
  background-color: #fff;
}

.links__item .links__item_featured:hover {
  background-color: #fff;
}

.links__item .links__item_product:hover {
  background-color: #fff;
}

.links__item .links__item_blog:hover {
  background-color: #fff;
}

.links__item .links__item_upcoming {
  @extend .links__item;
  border-bottom: 3px solid #ffa093;
  box-shadow: inset 0 -4px 0 #ffa093;
  border-radius: 25px;
  border: 2px solid #ffa093;
  background-color: #ffa093;
}

.links__item .links__item_promo {
  @extend .links__item;
  border-bottom: 3px solid #80d6ff;
  box-shadow: inset 0 -4px 0 #80d6ff;
  border-radius: 25px;
  border: 2px solid #80d6ff;
  background-color: #80d6ff;
}

.links__item .links__item_past_event {
  @extend .links__item;
  border-bottom: 3px solid #bedde8;
  box-shadow: inset 0 -4px 0 #bedde8;
  border-radius: 25px;
  border: 2px solid #bedde8;
  background-color: #bedde8;
}

.links__item .links__item_featured {
  @extend .links__item;
  box-shadow: inset 0 -4px 0 #73e2a7;
  border-radius: 25px;
  border: 2px solid #73e2a7;
  background-color: #73e2a7;
}

.links__item .links__item_product {
  @extend .links__item;
  border-bottom: 3px solid #ffc15e;
  box-shadow: inset 0 -4px 0 #ffc15e;
  border-radius: 25px;
  border: 2px solid #ffc15e;
  background-color: #ffc15e;
}

.links__item .links__item_alumni {
  @extend .links__item;
  border-bottom: 3px solid #fed766;
  box-shadow: inset 0 -4px 0 #fed766;
  border-radius: 25px;
  border: 2px solid #fed766;
  background-color: #fed766;
}

.links__item .links__item_blog {
  @extend .links__item;
  border-bottom: 3px solid #fd8176;
  box-shadow: inset 0 -4px 0 #fd8176;
  border-radius: 25px;
  border: 2px solid #fd8176;
  background-color: #fd8176;
}

.channels {
  display: flex;
  max-width: 400px;
  margin: 0 auto;
}

.channels .channels__item {
  flex: 1;
  margin-right: 5px;
  transition: transform 0.2s;
}

.channels__item:hover {
  transform: scale(1.5);
}

.tempLogo {
  width: 200px;
  height: 200px;
  margin-top: 20px;
  margin-bottom: 10px;
}

