.colCenterTitle {
  display: flex;
  justify-content: center;  
  padding-bottom: 15px;
  font-size: 35px;
  line-height: 120%;
}

.colCenter {
  display: flex;
  justify-content: center;
}

.fakeLink {
  color: #1565c0;
  text-decoration: underline;
}

.fakeLink:hover {
  color: #1e88e5;
  text-decoration: underline;
}

.errorMsg {
  padding-bottom: 13px;
  color: red;
}

.errorMsgnp {
  color: red;
}


.toInputPadding {
  padding-bottom: 10px;
}

.colSmallText {
  padding-top: 3px;
  font-size: 14px;
}

.containerCenter {
  margin: auto;
  width: 40vw;
  border-radius: 10px;
  padding: 20px;
  background-color: white;
  top: 5vh;
}

.submitButton {
  background-color: #D8AB4C;
  border-color: #D8AB4C;
}

.submitButton:hover {
  background-color: #CCA247;
  border-color: #CCA247;
}

.submitButton:active {
  background-color: #CCA247;
  border-color: #CCA247;
}

.submitButton:focus {
  background-color: #CCA247;
  border-color: #CCA247;
}

@media only screen and (max-device-width : 600px) {
  .containerCenter {
    width: 95vw;
  }
}
