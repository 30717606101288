.mainTitle {
  font-size: 40px;
  align-self: center;
  line-height: 107%;
  padding-top: 7px;
}
.globalFont {
  font-family: "Source Sans Pro";
  height: 100%;
  overflow: hidden;
  min-height: 100vh;
  background-color: #000000;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/vandyfood.appspot.com/o/bkg-compressed.png?alt=media&token=68953db6-7d2c-46db-9bbe-c6ae8afe3dcf"); //This also looks nice: https://cdn.vanderbilt.edu/vu-www4/zoom/vanderbilt_zoom-21.jpg
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /*background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80' width='80' height='80'%3E%3Cpath fill='%23d8ab4c' fill-opacity='0.4' d='M14 16H9v-2h5V9.87a4 4 0 1 1 2 0V14h5v2h-5v15.95A10 10 0 0 0 23.66 27l-3.46-2 8.2-2.2-2.9 5a12 12 0 0 1-21 0l-2.89-5 8.2 2.2-3.47 2A10 10 0 0 0 14 31.95V16zm40 40h-5v-2h5v-4.13a4 4 0 1 1 2 0V54h5v2h-5v15.95A10 10 0 0 0 63.66 67l-3.47-2 8.2-2.2-2.88 5a12 12 0 0 1-21.02 0l-2.88-5 8.2 2.2-3.47 2A10 10 0 0 0 54 71.95V56zm-39 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm40-40a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM15 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm40 40a2 2 0 1 0 0-4 2 2 0 0 0 0 4z'%3E%3C/path%3E%3C/svg%3E");*/
}

.makeRed {
  color: red;
  width: 80%;
  font-size: 25px;
  line-height: 1.1;
}

.colCenter {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.reportText {
  font-weight: bold;
}

.fakeLinkRed {
  color: red;
  text-decoration: underline;
  margin: 0 auto;
  font-size: 25px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.fakeLinkRed:hover {
  color: red;
}


.fakeLink {
  color: #1565c0;
  text-decoration: underline;
}

.fakeLinkReports {
  text-decoration: underline;
  display: block;
}

.fakeLink:hover {
  color: #1e88e5;
  text-decoration: underline;
}

.colCenternp {
  display: flex;
  justify-content: center;
}

.colCenternpp {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 22px;
}

.colButton {
  background-color: #D8AB4C;
  border-color: #D8AB4C;
}

.colButton:hover {
  background-color: #CCA247;
  border-color: #CCA247;
}

.colButton:active {
  background-color: #CCA247 !important;
  border-color: #CCA247 !important;
}

.colButton:focus {
  background-color: #CCA247;
  border-color: #CCA247;
}

.colCenter1 {
  font-size: 30px;
  line-height: 1.14;
  padding-top: 10px;
}

.petitionButton {
  background-color: #D8AB4C;
  border-color: #D8AB4C;

  border: none;
  padding: 1em 2em;
  font-size: 18px;
  font-family: "Source Sans Pro";
  border-radius: 5px;

  outline: 0 !important;
  
  &:hover {
    background-color: #CCA247;
    border-color: #CCA247;
  }

  &:active {
    background-color: #CCA247;
    border-color: #CCA247;
  }

  &:focus {
    background-color: #CCA247;
    border-color: #CCA247;
  }
}

.ib {
  display: inline-block;
}

.ib1 {
  display: inline-block;
  font-size: 100px;
  color:red;
}
.ib2 {
  display: inline-block;
  font-size: 40px;
  line-height: 1;
  width: 90%;
  color: red;
}

.ib3 {
  display: inline-block;
  font-size: 34px;
  line-height: 1;
  width: 90%;
  color: red;
  margin-bottom: 20px;
}

.resWidth {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.colCenter2 {
  display: flex;
  justify-content: center;
  font-size: 17px;
  line-height: 1.3;
  padding-top: 10px;
}

.openLetterHeader {
  display: flex;
  justify-content: center;
  font-size: 28px;
  line-height: 1.3;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 7.5%;
  padding-right: 7.5%;

}

.openLetter {
  font-size: 18px;
  line-height: 1.3;
  padding-top: 10px;
  padding-left: 7.5%;
  padding-right: 7.5%;
}

.letterText {
}


.openLetterSignature {
  display: flex;
  justify-content: left;
  font-size: 18px;
  padding-left: 7.5%;
  padding-right: 7.5%;
}


.openLetterSignature > img {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.vl {
  border-left: 6px solid black;
  height: 500px;
  position: absolute;
  left: 50%;
  margin-left: -3px;
  top: 0;
}

.signPetition {
  display: flex;
  justify-content: center;
  font-size: 17px;
  line-height: 1.3;
  padding-top: 15px;
  padding-bottom: 30px;
}

@media only screen and (max-device-width : 700px) {
  .desktopOnly {
    visibility: hidden;
  }
}

@media only screen and (min-device-width : 700px) {
  .mobileOnly {
    visibility: hidden;
  }
}


@media only screen and (min-device-width : 600px) {
  .colCenter2 > center > p {
    width: 80%;
  }
}


.tempLogo {
  width: 200px;
  height: 200px;
}

.mainContainerAnimate {
  animation-name: animate_up;
  animation-duration: 0.4s;
  position: relative;
  width: 60vw;
  padding: 20px;
  border-radius: 10px;
  background-color: white;
}
.mainContainer {
  background-color: white;
  position: relative;
  top: 5vh;
  width: 60vw;
  padding: 20px;
  border-radius: 10px;
}

@media only screen and (max-device-width : 600px) {
  .mainContainer {
    width: 95vw;
  }

  .mainContainerAnimate {
    width: 95vw;
  }
}

.register {
  top: 5vh;
  position: relative;
  animation-name: animate_register;
  animation-duration: 0.5s;
}

@keyframes animate_up {
  from {
    top: 5vh;
  }
  to {
    top: -100vh;
  }
}
@keyframes animate_register {
  from {
    top: 100vh;
  }
  to {
    top: 5vh;
  }
}
.logos{
  padding-left: 5px;
  padding-right: 5px;
  width: 60px;
  height: 50px;
}
.a:link{
  color: black;
}

@media only screen and (max-width: 600px) {
  .newText{
    font-size: 3vh;
    font-weight: 600;
  }
}

@media only screen and (min-width: 600px) {
  .newText{
    font-size: 30px;
    font-weight: 600;
  }
}


.timers{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 40px;
  margin-bottom: 50px;
}

.countdown{
  margin-top: 10px;
}

.time{
  font-size: 4vh;
}

.timeWrapper{
  text-align: center;
}


.dimension{
  font-size: 3vh;
}